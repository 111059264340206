import React, { useContext } from 'react'
import {
  getCurrentVideo,
  getIdsByCategory,
  getVideosByIds,
  randomizeVideos,
} from '../suggestedVideoHelpers'
import LodgingContext from '../../../contexts/lodgingData/lodgingData'
import SuggestedVideos from '../SuggestedVideos'
import VideoContext from '../../../contexts/videoData/videoData'
import PropTypes from 'prop-types'

const computeLodgingVideosForSinglePostByState = (
  selectedState,
  allReviews,
  allVideos,
  reviewId
) => {
  const currentVideo = getCurrentVideo(allVideos, reviewId)
  const otherVideosByState = randomizeVideos(
    getVideosByIds(
      getIdsByCategory(allReviews, selectedState).filter(
        video => video !== reviewId
      ),
      allVideos
    )
  )

  return [...currentVideo, ...otherVideosByState]
}
const LodgingVideosForSinglePostByState = ({ selectedState, reviewId }) => {
  const allVideos = useContext(VideoContext)
  const allReviews = useContext(LodgingContext)

  return (
    <SuggestedVideos
      videos={computeLodgingVideosForSinglePostByState(
        selectedState,
        allReviews,
        allVideos,
        reviewId
      )}
    />
  )
}

LodgingVideosForSinglePostByState.propTypes = {
  selectedState: PropTypes.string,
  reviewId: PropTypes.string,
}

export default LodgingVideosForSinglePostByState
